import {request} from "./request"
export default {
  news: {
    // getNews: function (option) {
    //   return request({
    //     url: '/wx/news.do',
    //     method: 'post',
    //     data: option
    //   })
    // }
    getNews: function ({sort, types, page, limit}) {
      return request({
        url: '/wx/news.do',
        method: 'post',
        data: {sort, types, page, limit}
      })
    }
  }
}
