import axios from 'axios'

let baseURL
if (process.env.NODE_ENV === "development") {
  baseURL = '/apis'
} else {
  baseURL = 'https://oa.fqchildren.com/apis'
}

export function request(config) {
  const instance = axios.create({
    baseURL,
    timeout: 60000,
  })
  instance.interceptors.response.use(response => {
    return response.data
  }, error => {
    console.error(error)
    return Promise.resolve('拦截到网络错误：' + error)
  })
  return instance(config)
}
