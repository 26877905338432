<template>
  <header class="layout-header"
          :class="{'layout-header--background-white': (/^\/event/.test($route.path) || /^\/test/.test($route.path))}">
    <div class="layout-header__container">
      <div class="layout-header__logo">
        <a href="/">
          <img src="../assets/img/logo_02@2x.png" alt="乐享无限">
          <span>乐享无限</span>
        </a>
      </div>
      <ul class="layout-header__nav-list">
        <li v-for="(item, index) in navList"
            :key="item.name"
            class="layout-header__nav-item"
            :class="{'layout-header__nav-item--active': $route.path === item.href || (/^\/test/.test($route.path) && index === 6)}"
            @mouseover="handleHover(index)"
            @mouseleave="handleLeave">
          <a :href="item.href">{{item.name}}</a>
        </li>
        <li class="layout-header__nav-line" :style="{left: lineLeft + 'px'}"></li>
      </ul>
    </div>
  </header>
</template>

<script>
  export default {
    name: "LayoutHeader",
    data() {
      return {
        navList: [
          {
            href: '/',
            name: '官网首页'
          },
          {
            href: '/introduction/',
            name: '乐享集团'
          },
          {
            href: '/event/',
            name: '品牌活动'
          },
          {
            href: '/service/',
            name: '产品服务'
          },
          {
            href: '/news/',
            name: '新闻中心'
          },
          {
            href: '/contact/',
            name: '联系我们'
          },
          {
            href: '/join/',
            name: '加入乐享'
          },
        ].reverse(),
        lineLeft: 70 + 72 / 2 - 30 / 2
      }
    },
    methods: {
      handleHover(index) {
        let realIndex = this.navList.length - 1 - index
        this.lineLeft = (70 + 72) * realIndex + 70 + 72 / 2 - 30 / 2
      },
      handleLeave() {
        // test, 正式版本删除这段代码
        if(/^\/test/.test(this.$route.path)) {
          this.lineLeft = (70 + 72) * 0 + 70 + 72 / 2 - 30 / 2
          return
        }

        let index = this.navList.findIndex(item => item.href === this.$route.path)
        if(index > -1) {
          index = this.navList.length - index - 1
          this.lineLeft = (70 + 72) * index + 70 + 72 / 2 - 30 / 2
        }
      }
    },
    created() {
      // 点击footer进入路由时, 手动触发线条位置改变
      this.handleLeave()
    }
  }
</script>

<style lang="scss" scoped>
.layout-header {
  height: 82px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  background: #ffffff00;
  padding: 0 150px;
  position: absolute;
  z-index: 99;
  // font
  color: #ffffff;
  font-size: 18px;
  line-height: 18px;
}
.layout-header__container {
  @include clearfix;
  min-width: 1190px;
}
.layout-header__logo {
  float: left;
  img {
    margin-top: (82px - 49px) / 2;
    float: left;
  }
  span {
    float: left;
    margin-left: 20px;

    // font
    color: #f39800;
    font-size: 24px;
    line-height: 82px;
  }
}
.layout-header__nav-list {
  float: right;
  position: relative;
  height: 82px;
  &:after {
    content: '';
    clear: both;
    display: block;
  }
}
.layout-header__nav-item {
  float: right;
  margin-left: 70px;
  margin-top: 32px;
  transition: all .5s;
  &:hover {
    color: #f39800;
  }
  &--active {
    color: #f39800;
  }
}
.layout-header__nav-line {
  height: 2px;
  width: 30px;
  background-color: #f39800;
  position: absolute;
  bottom: 0;
  transition: left 0.5s;
}

// 白色header背景
.layout-header--background-white {
  background-color: #ffffff;
  position: relative;
  color: #333333;
}

@media (max-width: 1500px) {
  .layout-header {
    padding: 0;
  }
  .layout-header__container {
    width: 1190px;
    margin: 0 auto;
  }
}
</style>
