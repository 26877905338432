import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "../components/Layout"

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('../views/home/Home.vue')
      },
      {
        path: 'introduction',
        component: () => import('../views/introduction/Introduction.vue')
      },
      {
        path: 'event',
        component: () => import('../views/event/Event.vue')
      },
      {
        path: 'service',
        component: () => import('../views/service/Service.vue')
      },
      {
        path: 'news',
        component: () => import('../views/news/News.vue')
      },
      {
        path: 'contact',
        component: () => import('../views/contact/Contact.vue')
      },
      {
        path: 'join',
        component: () => import('../views/join/Join.vue')
      },
      {
        path: 'test',
        component: () => import('../views/test/Test.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
