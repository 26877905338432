import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/global.scss'
import vueMeta from 'vue-meta'

Vue.config.productionTip = false

// 百度地图相关变量
window.BMAP_PROTOCOL = "https";
window.BMapGL_loadScriptTime = (new Date).getTime();

// seo
Vue.use(vueMeta)

import axios from './network/index.js'
import vuetify from './plugins/vuetify';
Vue.prototype.$axios = axios

new Vue({
  router,
  render: h => h(App),
  vuetify,

  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
