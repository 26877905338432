<template>
  <footer>
    <div class="footer__container">
      <!--第一行-logo和导航栏-->
      <div class="first-line">
        <img src="../assets/img/logo_02@2x.png" alt="乐享无限">
        <ul>
          <li v-for="item in navList" :key="item.name">
            <a :href="item.href">{{item.name}}</a>
          </li>
        </ul>
      </div>
      <!--第二行-二维码-->
      <div class="second-line">
        <ul>
          <li>
            <img src="../assets/img/qrcode-xiaobian.jpg" alt="乐享无限官方微信">
            <div class="second-line__text">
              <p>乐享无限</p>
              <p>官方微信</p>
            </div>
          </li>
          <li>
            <img src="../assets/img/qrcode-gzh.jpg" alt="扫一扫关注官方公众号">
            <div class="second-line__text">
              <p>扫一扫关注</p>
              <p>官方公众号</p>
            </div>
          </li>
          <li>
            <img src="../assets/img/qrcode-app.png" alt="乐享无限官方APP">
            <div class="second-line__text">
              <p>乐享无限</p>
              <p>官方app</p>
            </div>
          </li>
        </ul>

        <div class="second-line__hot-line">
          <p>
            <img src="../assets/img/icon_phone@2x.png" alt="">
            <span>客服热线</span>
          </p>
          <p>400-188-</p>
          <p>8633</p>
        </div>
      </div>
      <div class="last-line">
        <p>@2020 广州智菁通信息科技有限公司</p>
        <p><a href="https://beian.miit.gov.cn" target="_blank">粤ICP备16066948号</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "LayoutFooter",
    data() {
      return {
        navList: [
          {
            href: '/',
            name: '首页'
          },
          {
            href: '/introduction',
            name: '乐享集团'
          },
          {
            href: '/event',
            name: '品牌活动'
          },
          {
            href: '/service',
            name: '产品服务'
          },
          {
            href: '/news',
            name: '新闻中心'
          },
          {
            href: '/contact',
            name: '联系我们'
          },
          {
            href: '/join',
            name: '加入乐享'
          },
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  background-color: #333641;
}
.footer__container {
  width: 1100px;
  margin: 0 auto;
  background-color: #333641;
  color: #ffffff;
  @include clearfix;
}
.first-line {
  @include clearfix;
  img {
    margin: 26px 25px;
    float: left;
  }
  ul {
    @include clearfix;
    margin-left: 180px + 70px;
    margin-top: 40px;
    li {
      font-size: 16px;
      float: left;
      margin-right: 70px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #f39800;
      }
    }
  }
}

.second-line {
  @include clearfix;
  ul {
    @include clearfix;
    float: left;
    li {
      @include clearfix;
      float: left;
      margin-right: 80px;
      &:last-child {
        margin-right: 190px;
      }
      img {
        display: inline-block;
        width: 120px;
        height: 120px;
      }
      .second-line__text {
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
  &__hot-line {
    height: 120px;
    float: left;
    p:first-child {
      @include clearfix;
      font-size: 16px;
      line-height: 22px;
      img {
        float: left;
      }
      span {
        float: left;
        margin-left: 5px;
      }
    }
    p:nth-child(2),
    p:nth-child(3) {
      font-size: 40px;
      margin-left: 24px + 5px;
    }
    p:nth-child(2) {
      margin-top: 34px;
    }
  }
}

.last-line {
  margin-top: 30px;
  padding-bottom: 15px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  p {
    margin-top: 10px;
  }
}
</style>
