<template>
  <div>
    <layout-header/>
    <router-view/>
    <layout-footer/>
  </div>
</template>

<script>
  import LayoutHeader from "./LayoutHeader"
  import LayoutFooter from "./LayoutFooter"
  export default {
    name: "Layout",
    components: {
      LayoutHeader,
      LayoutFooter
    }
  }
</script>

<style lang="scss" scoped>

</style>
